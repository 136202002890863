module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"fr"}],"fields":[{"name":"title","store":true,"attributes":{"boost":10}},{"name":"processed","store":true,"attributes":{"boost":2}},{"name":"type","store":true},{"name":"typeName","store":true,"attributes":{"boost":5}},{"name":"url","store":true,"attributes":{"boost":1}},{"name":"taxo","store":true,"attributes":{"boost":5}},{"name":"address1","store":true,"attributes":{"boost":3}},{"name":"address2","store":true,"attributes":{"boost":3}},{"name":"ville","store":true,"attributes":{"boost":3}},{"name":"codePostal","store":true,"attributes":{"boost":3}},{"name":"field_affichage_planifie","store":true,"attributes":{"boost":0}},{"name":"field_date_de_publication","store":true,"attributes":{"boost":0}},{"name":"field_date_de_depublication","store":true,"attributes":{"boost":0}}],"resolvers":{"node__carnet_d_adresse":{},"node__actualites":{},"node__evenements":{},"node__page":{},"node__teleformulaires":{},"node__formulairepaiement":{}},"filename":"search_index.json"},
    },{
      plugin: require('../../node_modules/gatsby-theme-blank/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"vire-en-maconnais.fr","short_name":"vire-en-maconnais.fr","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"fullscreen","icon":"/builder-tmp/process-2edf4b53-bad3-4b7a-8c5c-af50dc89bd5e/site/static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e2cd83749871914bf2c1c26b5f53db56"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"599","matomoUrl":"https://web-suivis.ternum-bfc.fr","siteUrl":"https://vire-en-maconnais.fr","requireConsent":true},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
